
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { FileInfo, Notice, SocketMessage } from '@/interface/interface';
import ContentEditor from '@/components/editor.vue';
import BorderHeader from '@/pages/my-page/board/board-header.vue';
import FloatingButton from '@/components/floating-btn.vue';
import { NOTICE_TYPE } from '@/enum/enums';
import BoardMixin from '@/pages/my-page/board/board-mixin.vue';
import Uploader from '@/components/file-uploader.vue';
@Component({
  components: {
    ContentEditor,
    BorderHeader,
    BoardMixin,
    Uploader,
    SubmitButton: FloatingButton,
  }
})
export default class MyPageBoardReply extends Mixins(Vue, BoardMixin) {
  private noticeTypes: { text: string; value: NOTICE_TYPE }[] = [];
  private _id: string | null = null;
  private doc: Notice | null = null;
  private confirmModal = false;
  private uploadedFiles: FileInfo[] = [];
  async created(): Promise<void> {
    const { _id } = this.$route.params;
    this._id = _id;
    await this.load();
  }

  private async load() {
    if (!this._id) return;
    this.loading = true;
    this.noticeTypes = await this.getTypes(this._id);
    this.doc = await this.getContent(this._id);
    if (this.doc) {
      if (this.noticeTypes.length === 1) this.doc.type = this.noticeTypes[0].value;
      this.doc.title = `RE: ${this.doc.title}`;
      this.doc.content += `<hr><p>REPLY:</p>`;
    }
    await new Promise((resolve) => setTimeout(resolve, 500));
    this.loading = false;
  }

  private contentInput(content: string) {
    if (this.doc) this.doc.content = content;
  }

  private async save(): Promise<void> {
    if (this.doc === null) return;
    if (!this.confirmModal) {
      this.confirmModal = true;
      return;
    }
    this.proc(true);
    this.confirmModal = false;
    const { _id } = this.doc;
    const url = `/notice/${_id}`;
    const params = {
        _id,
        title: this.doc.title,
        content: this.doc.content,
        commentActive: this.doc.commentActive,
        targetGroup: this.doc.targetGroup,
        uploadedFiles: this.uploadedFiles,  // REPLY는 새로운 업로드 파일
    };
    const { result, data: { doc: { _id: newId } } } = await this.axios({
      url,
      method: 'PUT',
      data: params
    });
    this.proc(false);
    if (result && newId) {
      await this.$router.push({ path: `/my-page/board/${newId}` });
    }
  }

  uploaded(files: FileInfo[]) {
    this.uploadedFiles.push(...files);
  }

  removeFile(id: string): void {
    const index = this.uploadedFiles.findIndex(file => file.id === id);
    if (index > -1) this.uploadedFiles.splice(index, 1);
  }

  get editable() {
    if (this.noticeType === NOTICE_TYPE.ANSWER && this.answered) return false;
    return true;
  }

  get commentActive() {
    return this.doc ? this.doc.commentActive : false;
  }

  get noticeTypesENum () {
    return NOTICE_TYPE;
  }
  get targetGroup(): string[] {
    return this.doc ? this.doc.targetGroup : [];
  }
  get noticeType() {
    return this.doc ? this.doc.type : null;
  }
  get answered() {
    return this.doc ? this.doc.answered : false;
  }
  get contentLength(): string {
    return this.doc ? this.doc.content.length.digit() : '0';
  }
}
